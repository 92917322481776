import React, { Component } from "react";
import Slider from "react-slick";
import TrophyIcon from "../images/trophy-icon.svg";
import { useState, useEffect } from "react";
import axios from "axios";


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className="custom-arrow next-arrow"
      style={{ ...style, display: "block"}}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className="custom-arrow prev-arrow"
      style={{ ...style, display: "block"}}
      onClick={onClick}
    />
  );
}

export default function PrizeSlider() {

  const siteId = process.env.REACT_APP_SITE_ID;
  const APIURL = process.env.REACT_APP_API_URL;

  const [scroll, setScroll] = useState(false);
  const [DrawData, setDrawData] = useState([]);
  const [PrizeData, setPrizeData] = useState([]);
  const parser = new DOMParser();
  const [totalQuantities, setTotalQuantities] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    speed: 500,
    autoplaySpeed: 3000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    arrows : true,
    centerMode: true, 
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          arrows: false,
          centerMode: false, 
          centerPadding: '40px',
          slidesToShow: 3
        }
      },
      {
        breakpoint: 1000,
        settings: {
          arrows: false,
          centerPadding: '40px',
          slidesToShow: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerPadding: '40px',
          centerMode: false, 
          slidesToShow: 1
        }
      }
    ]
    // cssEase: "linear"
  };

  function calculateTotalQuantity(PrizeData) {
    let sum = 0;
    {PrizeData.forEach(item => {
      sum += item.prizeQuantity;
    })}
    return sum;
  }

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
    axios.get(APIURL + '/sites/' + siteId + '/draws').then(res => {

      let alldraw = res.data.content;
      let activeDraw = res.data.content.filter(item => item.drawIsClosed != 1);
      let selectedDraw = {};
      if (activeDraw.length > 0) {
        selectedDraw = activeDraw[0]
      } else {
        selectedDraw = alldraw[0];
      }
      setDrawData(selectedDraw);
    });
  }, []);

  useEffect(() => {
    axios.get(APIURL + `/draws/${DrawData.drawID}/prizes` , {
      withCredentials: true,
      credentials: "same-origin"
    }).then(res => {
      setPrizeData(res?.data?.content);
      setTotalQuantities(calculateTotalQuantity(res?.data?.content));
 });
 }, [DrawData])

  return (
    <div className="prize-block-inner px-0 py-5 pt-0">
      <Slider {...settings}>
        {PrizeData?.map(item => {
          return (
          <div className="prize-block">
            <div className="rounded-3 border m-2 bg-white">
              <div className="prize-photo bg-white col-sm-12 col-md-12 bg-white col-lg-12 text-center p-3 position-relative" style={{height: 330 , borderRadius : 12}}>
                  <img src={item.prizeImageURL} className="img-fluid" alt="Signed" style={{height : '100%', width : '100%', objectFit : 'cover', borderRadius : 12}}/>
              </div>
              <div className="prize-block-testimonial bg-white text-center py-3 col-sm-12 col-md-12 col-lg-12" style={{borderBottomLeftRadius : 12 ,borderBottomRightRadius : 12 }}>
                  <h4 className="branding-1" dangerouslySetInnerHTML={{ __html: parser.parseFromString(`${item.prizeName}`, 'text/html').body.textContent }} />
              </div>
           </div> 
          </div>
            )
        })}


          {/* <div className="prize-block">
            <div className="rounded-3 border m-2 bg-white">
              <div className="prize-photo bg-white col-sm-12 col-md-12 bg-white col-lg-12 text-center p-3 position-relative" style={{height: 330 , borderRadius : 12}}>
                  <img src={require('../images/training.png')} className="img-fluid" alt="Training" style={{height : '100%', width : '100%', objectFit : 'cover', borderRadius : 12}}/>
              </div>
              <div className="prize-block-testimonial bg-white text-center py-3 col-sm-12 col-md-12 col-lg-12" style={{borderBottomLeftRadius : 12 ,borderBottomRightRadius : 12 }}>
                  <h4 className="branding-1">Training session with my trainer Leanne Marshall</h4>
              </div>
           </div> 
          </div>

          <div className="prize-block">
            <div className="rounded-3 border m-2 bg-white">
              <div className="prize-photo bg-white col-sm-12 col-md-12 bg-white col-lg-12 text-center p-3 position-relative" style={{height: 330 , borderRadius : 12}}>
                  <img src={require('../images/perform.webp')} className="img-fluid" alt="Choir" style={{height : '100%', width : '100%', objectFit : 'cover', borderRadius : 12}}/>
              </div>
              <div className="prize-block-testimonial bg-white text-center py-3 col-sm-12 col-md-12 col-lg-12" style={{borderBottomLeftRadius : 12 ,borderBottomRightRadius : 12 }}>
                  <h4 className="branding-1">Choir session with me and a chance to perform together</h4>
              </div>
           </div> 
          </div>

          <div className="prize-block">
            <div className="rounded-3 border m-2 bg-white">
              <div className="prize-photo bg-white col-sm-12 col-md-12 bg-white col-lg-12 text-center p-3 position-relative" style={{height: 330 , borderRadius : 12}}>
                  <img src={require('../images/frozen.jpg')} className="img-fluid" alt="Frozen" style={{height : '100%', width : '100%', objectFit : 'cover', borderRadius : 12}}/>
              </div>
              <div className="prize-block-testimonial bg-white text-center py-3 col-sm-12 col-md-12 col-lg-12" style={{borderBottomLeftRadius : 12 ,borderBottomRightRadius : 12 }}>
                  <h4 className="branding-1">Westend show tickets with meet and greet with the stars of Frozen</h4>
              </div>
           </div> 
          </div>

          <div className="prize-block">
            <div className="rounded-3 border m-2 bg-white">
              <div className="prize-photo bg-white col-sm-12 col-md-12 bg-white col-lg-12 text-center p-3 position-relative" style={{height: 330 , borderRadius : 12}}>
                  <img src={require('../images/o-WE-WILL-ROCK-YOU-570.jpg')} className="img-fluid" alt="Frozen" style={{height : '100%', width : '100%', objectFit : 'cover', borderRadius : 12}}/>
              </div>
              <div className="prize-block-testimonial bg-white text-center py-3 col-sm-12 col-md-12 col-lg-12" style={{borderBottomLeftRadius : 12 ,borderBottomRightRadius : 12 }}>
                  <h4 className="branding-1">Westend show tickets with meet and greet with the stars of We Will Rock You</h4>
              </div>
           </div> 
          </div>

          <div className="prize-block">
            <div className="rounded-3 border m-2 bg-white">
              <div className="prize-photo bg-white col-sm-12 col-md-12 bg-white col-lg-12 text-center p-3 position-relative" style={{height: 330 , borderRadius : 12}}>
                  <img src={require('../images/wicked.jpg')} className="img-fluid" alt="soundcheck" style={{height : '100%', width : '100%', objectFit : 'cover', borderRadius : 12}}/>
              </div>
              <div className="prize-block-testimonial bg-white text-center py-3 col-sm-12 col-md-12 col-lg-12" style={{borderBottomLeftRadius : 12 ,borderBottomRightRadius : 12 }}>
                  <h4 className="branding-1">Westend show tickets with meet and greet with the stars of Wicked</h4>
              </div>
           </div> 
          </div>

          <div className="prize-block">
            <div className="rounded-3 border m-2 bg-white">
              <div className="prize-photo bg-white col-sm-12 col-md-12 bg-white col-lg-12 text-center p-3 position-relative" style={{height: 330 , borderRadius : 12}}>
                  <img src={require('../images/we_will.jpg')} className="img-fluid" alt="Tickets" style={{height : '100%', width : '100%', objectFit : 'cover', borderRadius : 12}}/>
              </div>
              <div className="prize-block-testimonial bg-white text-center py-3 col-sm-12 col-md-12 col-lg-12" style={{borderBottomLeftRadius : 12 ,borderBottomRightRadius : 12 }}>
                  <h4 className="branding-1">Press night Tickets to We Will Rock You</h4>
              </div>
           </div> 
          </div>

          <div className="prize-block">
            <div className="rounded-3 border m-2 bg-white">
              <div className="prize-photo bg-white col-sm-12 col-md-12 bg-white col-lg-12 text-center p-3 position-relative" style={{height: 330 , borderRadius : 12}}>
                  <img src={require('../images/studio.png')} className="img-fluid" alt="Studio" style={{height : '100%', width : '100%', objectFit : 'cover', borderRadius : 12}}/>
              </div>
              <div className="prize-block-testimonial bg-white text-center py-3 col-sm-12 col-md-12 col-lg-12" style={{borderBottomLeftRadius : 12 ,borderBottomRightRadius : 12 }}>
                  <h4 className="branding-1">Studio session with me to record a song in a London studio</h4>
              </div>
           </div> 
          </div> */}

      </Slider>
    </div>
  );
}
