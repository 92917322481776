import { React, useState , useEffect } from 'react';
import { Link,useNavigate  } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import secureLocalStorage from 'react-secure-storage'
import LogoWhite from '../images/Kerry-Ellis-Logo.png'
import axios from 'axios'
import Moment from 'moment';
import Header from '../components/Header';
import Facebook from '../images/facebook.svg'
import Twitter from '../images/twitter.svg'
import Instagram from '../images/instagram.svg'
import Visa from '../images/visa.svg'
import Mastercard from '../images/mastercard.svg'
import LogoFoundationWhite from '../images/logo-foundation-white.svg'
import Cup from '../images/cup.svg'
import  '../images/HeroBg.png'
import '../App.css'
import LogoFoundationGray from '../images/Kerry-Ellis-Logo.png'
import CurevedDot from '../images/cureved-dot.svg'
import FooterIconAddress from '../images/footer-icon-address.svg'
import FooterIconEmail from '../images/footer-icon-email.svg'


export default function PageNotFound() {
  const [scroll, setScroll] = useState(false);
  const [Sidebar,setSidebar] = useState(false);
  const [login ,setLogin] = useState(secureLocalStorage.getItem('LogObject') ? secureLocalStorage.getItem('LogObject').loggedin : false);
  const [DrawData,setDrawData] = useState([]);
  
  const siteId = process.env.REACT_APP_SITE_ID;
  const APIURL = process.env.REACT_APP_API_URL;

  function logout(){
    secureLocalStorage.clear();
    axios.delete(`${process.env.REACT_APP_API_URL}/auth/logout`);
    setLogin(false);
  }

  return (
    
    <>
    <header className={scroll ? "home site-header font-face-sh py-4 position-sticky top-0 bg-branding-1 z-index-9" : "home site-header font-face-sh bg-branding-1 py-4"}>
      <div className='container'>
        <div className='inner-header row d-flex align-items-center'>
          <div className='logo col-sm-6 col-md-6 col-lg-6 col-6'>
            <a href="/"><img className='start-0' src={LogoWhite} alt="logo-white" style={{ width: 150 }} /></a>
          </div>
          <div className='desktop-menu col-md-6 d-flex justify-content-end align-items-center'>
            {login ?
              <>
                <DropdownButton
                  align="end"
                  title="Account"
                  id="dropdown-menu-align-end"
                  className="text-white transparent"

                >
                  <Dropdown.Item eventKey="1" href="/account">My Impact</Dropdown.Item>
                  <Dropdown.Item eventKey="2" href="/account/prizes">Prizes</Dropdown.Item>
                  <Dropdown.Item eventKey="3" href="/account/subscription">Manage Subscription</Dropdown.Item>
                  <Dropdown.Item eventKey="6" href="/account/payment-method">Manage Payment</Dropdown.Item>
                  <Dropdown.Item eventKey="4" href="/account/details">Settings</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item eventKey="5" onClick={() => logout()}>Log out</Dropdown.Item>
                </DropdownButton>
                <Link to={"/subscription-plan"} className='py-3 px-5 border border-2 border-white rounded-pill bg-branding-1 text-white text-decoration-none fs-6 ms-4 lh-1 font-face-sh'>Enter The Draw</Link>
              </>
              :
              <>
                <Link to={"/login"} className='text-white text-decoration-none fs-6 font-face-sh-bold'>Login</Link>
                <Link to={"/signup"} className='py-3 px-5 border border-2 border-white rounded-pill text-white text-decoration-none fs-6 ms-4 lh-1 font-face-sh-bold'>Join Now</Link>
              </>}
          </div>
          <div className='col-sm-6 col-md-6 col-6 mobile-menu justify-content-end d-flex align-items-center d-none'>
            <div className={Sidebar ? "MenuButton_rootActive" : "MenuButton_root"} onClick={() => setSidebar(!Sidebar)}>
              <div className={Sidebar ? "MenuButton_firstLineActive" : "MenuButton_firstLine"}></div>
              <div className={Sidebar ? "MenuButton_secondLineActive" : "MenuButton_secondLine"}></div>
              <div className={Sidebar ? "MenuButton_thirdLineActive" : "MenuButton_thirdLine"}></div>
            </div>
          </div>
        </div>
      </div>

      <div id="mySidebar" className={Sidebar ? "open sidebar bg-light h-100 top-0 end-0 position-fixed d-flex flex-column ease-in-out overflow-hidden py-4 px-4" : "sidebar h-100 w-0 end-0 top-0 bg-light position-fixed pt-2 ease-in-out overflow-hidden"}>
        <div className='logo-red col-md-12'>
          <img className='start-0' src={LogoFoundationGray} alt="logo-white" style={{ width: 150 }} />
        </div>
        <div className='mobile-menu-nav col-md-12 mt-5 d-flex flex-column'>
          {login ?
            <>
              <Link to={"/subscription-plan"} className='text-center mb-3 py-3 px-5 border border-2 border-white rounded-pill bg-branding-1 text-white text-decoration-none fs-6 lh-1 font-face-sh'>Enter The Draw</Link>
              <div className='fs-6 mb-1' style={{ color: '#999' }}><strong>Account</strong></div>
              {/* <Link to={"/login"} className='black-clr text-decoration-none fs-5 font-face-sh-bold branding-1-hover'>Login</Link>
            <Link to={"/signup"} className='py-3 px-5 border border-2 border-branding-1 rounded-pill black-clr text-decoration-none branding-1-hover fs-5 mt-3 w-75 text-center lh-1'>Join Now</Link> */}
              <Link to={"/account"} className='black-clr text-decoration-none fs-5 branding-1-hover py-1'>My Impact</Link>
              <Link to={"/account/prizes"} className='black-clr text-decoration-none fs-5 branding-1-hover py-1'>Prizes</Link>
              <Link to={"/account/subscription"} className='black-clr text-decoration-none fs-5 branding-1-hover py-1'>Manage Subscription</Link>
              <Link to={"/account/payment-method"} className='black-clr text-decoration-none fs-5 branding-1-hover py-1'>Manage Payment</Link>
              <Link to={"/account/details"} className='black-clr text-decoration-none fs-5 branding-1-hover py-1'>Settings</Link>
              <hr />
              <Link onClick={logout} to={"/"} className='black-clr text-decoration-none fs-5 branding-1-hover'>Log Out</Link>
            </>
            :
            <>
              <Link to={"/login"} className='black-clr text-decoration-none fs-5 font-face-sh-bold branding-1-hover'>Login</Link>
              <Link to={"/signup"} className='py-3 px-5 border border-2 border-branding-1 rounded-pill black-clr text-decoration-none branding-1-hover fs-5 mt-3 w-75 text-center lh-1'>Join Now</Link>
            </>}
        </div>

        <div className='col-sm-12 col-md-12 col-lg-3 mt-auto'>
          <div className='row'>
            <div className='col-sm-12 col-md-12 col-lg-12 branding-1'>
              <p className='mt-4 pt-2 font-face-sh-bold'>Follow us</p>
              <div className='d-flex'>
              <a className="black-clr text-decoration-none" href="https://www.facebook.com/officialkerryellis/" target='_blank'><img className="me-3" src={Facebook} alt="Facebook" style={{width: 24}} /></a>
                <a className='black-clr text-decoration-none' href="https://twitter.com/kerryjaneellis1" target='_blank'><img className='me-3' src={Twitter} alt="Twitter" style={{width: 24}}/></a>
                <a className='black-clr text-decoration-none' href="https://www.instagram.com/kerryellis79/" target='_blank'><img className='me-3' src={Instagram} alt="Instagram" style={{width: 24}}/></a>
              </div>
            </div>
            <div className='col-sm-12 col-md-12 col-lg-12 branding-1'>
              <p className='mt-4 pt-2' style={{ fontSize: 14 }}>The Kerry Ellis Prize Draw is operated by <a className='text-decoration-none branding-1 branding-1-hover' href="https://playfundwin.com/" target="_blank">Play Fund Win</a></p>
              <div className='d-flex font-face-sh-bold' style={{ fontSize: 14 }}>
                <Link className='branding-1 text-decoration-none pe-5 branding-1-hover' to={'/terms-conditions'}>Terms & Conditions</Link>
                <Link className='branding-1 text-decoration-none branding-1-hover' to={'/privacy-policy'}>Privacy Policy</Link>
              </div>
            </div>
          </div>
        </div>
      </div>

    </header>
    
    <section className="w-100 bg-f2  p-5 position-relative notfound-page">
        <div className="container h-100 p-5 notfound-page">
          <div className="d-flex align-items-center justify-content-center h-100 ">
            <div className="d-flex flex-column w-75 row notfound">
              <div className='align-items-center justify-content-center text-center'>
                <img src={require('../images/404kerry.png')} style={{width : "100%"}}/>
                <h1 className='display-4 mb-5 font-face-sh-bold'>Ohh... Shoot, this isn't the right place</h1>
                <h5 className='color-grey'>This page you have navigated doesn't exist or has been moved. Our apologies</h5>
                <div className='pt-0'>
                  <Link to={'/'} className="btn btn-link btn bg-branding-1 text-decoration-none shadow-lg text-white border-0 notfound-button fs-4 w-25 m-3 rounded-pill py-3 mt-5 lh-1">Home</Link>
                  <Link to={'/login'} className="btn btn-link btn bg-branding-1 text-decoration-none shadow-lg text-white border-0 notfound-button fs-4  w-25 m-3 rounded-pill py-3 mt-5 lh-1">Login</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer className='footer-section font-face-sh mt-5 position-relative'>
      <div className='container'>
        <div className='footer-logo'>
          <img className='' src={LogoFoundationGray} alt="logo-foundation-gray" style={{width: 130}}/>
        </div>
        <div className='row pt-4'>
          <div className='col-sm-12 col-md-12 col-lg-5 black-clr'>
            <h4 className='mb-4 pb-2 pt-2 font-face-sh-bold'>Contact us</h4>
            <div className='footer-address d-flex align-items-start'>
              <img className='me-3' src={FooterIconAddress} alt="FooterIconAddress" style={{width: 24}}/>
              <p style={{fontSize: 14}}>Post: PFW Holdings Ltd Suite#300, 4 Blenheim Court, Peppercorn Close, Peterborough, PE1 2DU</p>
            </div>
            <div className='footer-email d-flex align-items-start'>
              <img className='me-3' src={FooterIconEmail} alt="FooterIconEmail" style={{width: 24}}/>
              <p style={{fontSize: 14}}>Support: <a href="mailto:hello@playfundwin.com" className='text-decoration-none black-clr branding-1-hover'>hello@playfundwin.com</a></p>
            </div>
          </div>
          <div className='col-sm-12 col-md-12 col-lg-3 mt-md-5'>
            <div className='row'>
              <div className='col-sm-12 col-md-12 col-lg-6 black-clr mb-3'>
                <p className='mt-4 pt-2 font-face-sh-bold'>Follow us</p>
                <div className='d-flex'>
                  <a className="black-clr text-decoration-none" href="https://www.facebook.com/officialkerryellis/" target='_blank'><img className="me-3" src={Facebook} alt="Facebook" style={{width: 24}} /></a>
                  <a className='black-clr text-decoration-none' href="https://twitter.com/kerryjaneellis1" target='_blank'><img className='me-3' src={Twitter} alt="Twitter" style={{width: 24}}/></a>
                  <a className='black-clr text-decoration-none' href="https://www.instagram.com/kerryellis79/" target='_blank'><img className='me-3' src={Instagram} alt="Instagram" style={{width: 24}}/></a>
                </div>
              </div>
              <div className='col-sm-12 col-md-12 col-lg-6'>
                <div className='d-flex mt-md-5 pt-2'>
                  <img className='me-3' src={Visa} alt="Visa" style={{width: 64}}/>
                  <img className='me-3' src={Mastercard} alt="Mastercard" style={{width: 64}}/>
                </div>
              </div>
            </div>
          </div>
          
          <div className='col-sm-12 col-md-12 col-lg-4  mt-md-5 black-clr ps-md-5'>
            <p className='mt-4 pt-3' style={{fontSize: 13}}>The Kerry Ellis Prize Draw is operated by <a className='text-decoration-none black-clr branding-1-hover' href="https://playfundwin.com/" target="_blank">Play Fund Win</a></p>
            <div className='d-flex' style={{fontSize: 14}}>
              <Link className='black-clr text-decoration-none pe-5 branding-1-hover font-face-sh-bold' to={'/terms-conditions'}>Terms & Conditions</Link>
              <Link className='black-clr text-decoration-none branding-1-hover font-face-sh-bold' to={'/privacy-policy'}>Privacy Policy</Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
      </>
  )
}