import { React, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import LogoWhite from "../images/Kerry-Ellis-Logo.png";
import LogoRed from "../images/Kerry-Ellis-Logo.png";
import HeaderLeft from "../images/header-left-image.svg";
import TopBg from "../images/Kerry-Ellis-Feature.png";
import rightBg from "../images/tickets-prize.png";
import leftBg from "../images/LG-TV.jpg";
import SignUp from "../images/playicon.png";
import Trophy from "../images/winicon.png";
import Charity from "../images/fundicon.png";
import Bg from "../images/bg.svg";
import Arrow from "../images/arrow.svg";
import LogoFoundationGray from "../images/Kerry-Ellis-Logo.png";
import CurevedDot from "../images/cureved-dot.svg";
import FooterIconAddress from "../images/footer-icon-address.svg";
import FooterIconEmail from "../images/footer-icon-email.svg";
import Facebook from "../images/facebook.svg";
import Twitter from "../images/twitter.svg";
import Instagram from "../images/instagram.svg";
import Visa from "../images/visa.svg";
import Mastercard from "../images/mastercard.svg";
import LogoFoundationWhite from "../images/Kerry-Ellis-Logo.png";
import HeaderRight from "../images/Kerry-Ellis-Logo.png";
import SidebarLogo from "../images/kerry-ellis-black.png";
import Cup from "../images/cup.svg";
import "../images/HeroBg.png";
import "../App.css";
import TestimonialSlider from "../components/testimonialSlider";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import apiClient from "../api.config";
import useDocumentTitle from "../components/useDocumentTitle";
import Moment from "moment";
import PrizeSlider from "../components/prizeSlider";
import { toast, ToastContainer } from "react-toastify";
import TicketImg1 from "../images/ticket-img1.svg";
import TicketImg2 from "../images/ticket-img2.svg";
import TicketImg3 from "../images/ticket-img3.svg";
import NextArrow from "../images/next-arrow.svg";
import Cross from "../images/cross.svg";
import FreeEntryIcon from "../images/free-entry-icon.svg";
import StripeCheckout from "react-stripe-checkout";
import { Globalvar } from "../components/global";
import StripeForm from "../components/stripeCheckout";
import { loadStripe } from "@stripe/stripe-js";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import LogoBottom from "../images/pfw-logo-final-logo-full-colour-rgb.png";
import { Elements, useElements } from "@stripe/react-stripe-js";
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);
// const stripePromise = loadStripe('pk_test_51J0lzQDskTItAbcC8D9RpKIxhDgdkMJtytfO1VijW6FlEwjbba9L0u2vmDawgw9FaXfHe6W5X9GB3tx6HaUgvEb500ztI0saiC');

export default function Home() {
  useDocumentTitle("Home");
  const [scroll, setScroll] = useState(false);
  const [Sidebar, setSidebar] = useState(false);
  const [login, setLogin] = useState(secureLocalStorage.getItem("LogObject") ? secureLocalStorage.getItem("LogObject").loggedin : false);
  const [DrawData, setDrawData] = useState([]);

  const siteId = process.env.REACT_APP_SITE_ID;
  const APIURL = process.env.REACT_APP_API_URL;

  const [paymentDetails, setPaymentDetails] = useState(false);
  const [SubscriptionPlan, setSubscriptionPlan] = useState(true);
  const [Freemodal, setFreemodal] = useState(false);
  const [isActive1, setIsActive1] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(true);
  const [isActive4, setIsActive4] = useState(false);
  const [isActive5, setIsActive5] = useState(false);
  const [isActive6, setIsActive6] = useState(false);
  const [amount, setAmount] = useState(25);
  const [entries, setEntries] = useState(5);
  const [consent1, setConsent1] = useState(false);
  const [consent2, setConsent2] = useState(false);
  const [consent3, setConsent3] = useState(false);
  const [userEmail, setEmail] = useState();
  const [fullName, setFullName] = useState("");
  const [userFirstName, setFirstName] = useState("");
  const [userLastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [age, setAge] = useState(0);
  const [SendDate, setSendDate] = useState();
  const [gender, setGender] = useState(1);
  const [oneShow, setOneShow] = useState(false);
  const [isoneoff, setIsoneoff] = useState(true);
  const [isStripe, setStripe] = useState(false);
  const [UserID, setUserId] = useState(secureLocalStorage.getItem("LogObject") ? secureLocalStorage.getItem("LogObject").userID : "");
  const userData = secureLocalStorage.getItem("LogObject");

  const navigate = useNavigate();

  function logout() {
    secureLocalStorage.clear();
    axios.delete(`${process.env.REACT_APP_API_URL}/auth/logout`);
    setLogin(false);
  }

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
    axios.get(APIURL + "/sites/" + siteId + "/draws").then((res) => {
      let alldraw = res.data.content;
      let activeDraw = res.data.content.filter((item) => item.drawIsClosed != 1);
      let selectedDraw = {};
      if (activeDraw.length > 0) {
        selectedDraw = activeDraw[0];
      } else {
        selectedDraw = alldraw[0];
      }
      setDrawData(selectedDraw);
    });
  }, []);

  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  function hideModal() {
    setOneShow(false);
  }
  Globalvar.amount = 25;
  Globalvar.entries = 5;

  useEffect(() => {
    if (!userData) {
    } else {
      axios
        .get(`${process.env.REACT_APP_API_URL}/users/${userData.userID}/data`, {
          withCredentials: true,
          credentials: "same-origin",
        })
        .then((res) => {
          setEmail(res.data.content.userEmail);
          setFirstName(res.data.content.userFirstName);
          setLastName(res.data.content.userSurname);
          setFullName(res.data.content.userFirstName + " " + res.data.content.userSurname);
        });
    }
  }, []);

  const handleClick = (param, value, tickets) => {
    switch (param) {
      case "first":
        setAmount(value);
        Globalvar.amount = value;
        Globalvar.entries = tickets;
        secureLocalStorage.setItem("amount", value);
        secureLocalStorage.setItem("entries", tickets);
        setEntries(tickets);
        setIsActive1(true);
        setIsActive2(false);
        setIsActive3(false);
        setIsActive4(false);
        setIsActive5(false);
        setIsActive6(false);
        return;
      case "second":
        setAmount(value);
        Globalvar.amount = value;
        Globalvar.entries = tickets;
        secureLocalStorage.setItem("amount", value);
        secureLocalStorage.setItem("entries", tickets);
        setEntries(tickets);
        setIsActive1(false);
        setIsActive2(true);
        setIsActive3(false);
        setIsActive4(false);
        setIsActive5(false);
        setIsActive6(false);
        return;
      case "third":
        setAmount(value);
        Globalvar.amount = value;
        Globalvar.entries = tickets;
        secureLocalStorage.setItem("amount", value);
        secureLocalStorage.setItem("entries", tickets);
        setEntries(tickets);
        setIsActive1(false);
        setIsActive2(false);
        setIsActive3(true);
        setIsActive4(false);
        setIsActive5(false);
        setIsActive6(false);
        return;
      case "fourth":
        setAmount(value);
        Globalvar.amount = value;
        Globalvar.entries = tickets;
        secureLocalStorage.setItem("amount", value);
        secureLocalStorage.setItem("entries", tickets);
        setEntries(tickets);
        setIsActive1(false);
        setIsActive2(false);
        setIsActive3(false);
        setIsActive4(true);
        setIsActive5(false);
        setIsActive6(false);
        return;
      case "fifth":
        setAmount(value);
        Globalvar.amount = value;
        Globalvar.entries = tickets;
        secureLocalStorage.setItem("amount", value);
        secureLocalStorage.setItem("entries", tickets);
        setEntries(tickets);
        setIsActive1(false);
        setIsActive2(false);
        setIsActive3(false);
        setIsActive4(false);
        setIsActive5(true);
        setIsActive6(false);
        return;
      case "sixth":
        setAmount(value);
        Globalvar.amount = value;
        Globalvar.entries = tickets;
        secureLocalStorage.setItem("amount", value);
        secureLocalStorage.setItem("entries", tickets);
        setEntries(tickets);
        setIsActive1(false);
        setIsActive2(false);
        setIsActive3(false);
        setIsActive4(false);
        setIsActive5(false);
        setIsActive6(true);
        return;
      default:
        setEntries(5);
        setAmount(25);
        setIsActive1(false);
        setIsActive2(false);
        setIsActive3(true);
        setIsActive4(false);
        setIsActive5(false);
        setIsActive6(false);
        return;
    }
  };

  function toPaymentDetails() {
    setSubscriptionPlan(false);
    setPaymentDetails(true);

    // Data Layer Push for Add to Cart event
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "add_to_cart",
      ecommerce: {
        user_id: userData?.userID ? userData?.userID : "Guest User",
        user_email: userData?.email ? userData?.email : "Guest User",
        items: [
          {
            item_name: DrawData.drawName,
            item_id: DrawData.drawID,
            price: amount,
            item_brand: "Sport",
            item_category:
              DrawData.drawCategoryID === 1
                ? "Fixed Cash Prize"
                : DrawData.drawCategoryID === 2
                ? "Split Cash Prize"
                : DrawData.drawCategoryID === 3
                ? "Experiential"
                : DrawData.drawCategoryID === 4
                ? "Hybrid"
                : "",
            item_variant: !isoneoff ? "Subscription" : "One-off",
            quantity: entries,
          },
        ],
      },
    });

    // Data Layer Push for Begin Checkout event
    window.dataLayer.push({
      event: "begin_checkout",
      ecommerce: {
        user_id: userData?.userID ? userData?.userID : "Guest User",
        user_email: userData?.email ? userData?.email : "Guest User",
        items: [
          {
            item_name: DrawData.drawName,
            item_id: DrawData.drawID,
            price: amount,
            item_brand: "Sport",
            item_category:
              DrawData.drawCategoryID === 1
                ? "Fixed Cash Prize"
                : DrawData.drawCategoryID === 2
                ? "Split Cash Prize"
                : DrawData.drawCategoryID === 3
                ? "Experiential"
                : DrawData.drawCategoryID === 4
                ? "Hybrid"
                : "",
            item_variant: !isoneoff ? "Subscription" : "One-off",
            quantity: entries,
          },
        ],
      },
    });
  }

  // else if (userFirstName == '') {
  //   toast("Please Enter the First Name", { type: "error" });
  // }
  // else if (userLastName == '') {
  //   toast("Please Enter the Last Name", { type: "error" });
  // }

  async function checkConsents() {
    const fn = fullName.split(" ");

    // const checkConsents = () => {
    if (!consent3) {
      toast("Please read and accept Terms & Conditions", { type: "error" });
    } else if (fullName === "") {
      toast.error(`Please Enter Your valid Full Name`);
    } else if (!fn[0] || fn[0] === "") {
      toast.error(`Please Enter Your First and last Name`);
    } else if (!fn[1] || fn[1] === "") {
      toast.error(`Please Enter Your last Name`);
    } else if (userEmail == "") {
      toast("Please Enter the Email Address", { type: "error" });
    } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(userEmail)) {
      toast.error(`Invalid Email`);
    }
    // else if (dob === '') {
    //   toast.error(`Please Enter Your Date of birth`);
    // }
    // else if (dob.length < 10) {
    //   toast.error(`Please Enter Your valid Date of birth`);
    // }
    // else if(age <= 18) {
    //   console.log('age + ' + age);
    //   toast.error(`Sorry. Only users over the age of eighteen are allowed to enter this Prize Draw" then we're good to go live!`);
    // }
    else {
      setStripe(true);
      setOneShow(true);

      //ValidateEmail
      // const POSTCODER_KEY =
      //   process.env.POSTCODER_API_KEY || "PCWW5-FVLAY-K6FGB-KSTYM";
      // await axios
      //   .get(`https://ws.postcoder.com/pcw/${POSTCODER_KEY}/email/${userEmail}`)
      //   .then((res) => {
      //     console.log("res.data", res.data);
      //     if (res.data.valid) {
      //       setStripe(true);
      //       setOneShow(true);
      //     } else {
      //       toast.error(`Invalid Email`);
      //     }
      //   });
    }
  }

  const targetDate = new Date(DrawData.drawEndDate);

  // Get the current date
  const currentDate = new Date();

  // Calculate the time difference in milliseconds
  const timeDiff = targetDate.getTime() - currentDate.getTime();

  // Convert the time difference to days
  const remainingDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
  let remainingHours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  let remainingMinutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

  return (
    <>
      <div className="tp-banner p-3 bg-branding-2 text-center font-face-sh-bold">
        <p className="fs-3 m-0">
          <span className="branding-2">SUPPORT GOOD CAUSE</span>
          <span className="text-white"> AND WIN THE ULTIMATE KERRY ELLIS PRIZES</span>
        </p>
      </div>
      <header className="home transation-ease-in-out site-header font-face-sh py-4 position-sticky top-0 bg-black z-index-9">
        <div className="container-full">
          <div className="inner-header row d-flex align-items-center">
            <div className="logo col-sm-3 col-md-3 col-lg-3 col-3">
              <img className="start-0 not-responsive" src={scroll ? LogoRed : LogoRed} alt="logo-white" style={{ width: 150 }} />
              <img className="start-0 only-responsive d-none" src={LogoRed} alt="logo-white" style={{ width: 150 }} />
            </div>
            <div className="col-sim-6 col-md-6 col-lg-6 col-6 text-center">
              <p className="fs-4 p-0 m-0 text-white font-face-sh-bold text-uppercase">
                WINNER DRAWN ON {Moment(DrawData.drawEndDate).format("MMMM Do, YYYY")} <span className="branding-2">{remainingDays} DAYS TO GO</span>
              </p>
            </div>
            <div className="desktop-menu col-md-3 d-flex justify-content-end align-items-center">
              {login ? (
                <>
                  <DropdownButton align="end" title="Account" id="dropdown-menu-align-end" className="text-white transparent">
                    <Dropdown.Item eventKey="1" href="/account">
                      My Impact
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="2" href="/account/prizes">
                      Prizes
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="3" href="/account/subscription">
                      Manage Subscription
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="6" href="/account/payment-method">
                      Manage Payment
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="4" href="/account/details">
                      Settings
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item eventKey="5" onClick={() => logout()}>
                      Log out
                    </Dropdown.Item>
                  </DropdownButton>
                  <Link
                    to={"/subscription-plan"}
                    className="py-3 px-5 border border-2 border-white rounded-pill bg-branding-1 text-white text-decoration-none fs-6 ms-4 lh-1 font-face-sh"
                  >
                    Enter The Draw
                  </Link>
                </>
              ) : (
                <>
                  <>
                    <Link to={"/login"} className="black-clr text-white text-decoration-none fs-5 font-face-sh-bold branding-1-hover" style={{ marginRight: "15px" }}>
                      Login
                    </Link>
                    <Link
                      to={"/signup"}
                      className="py-3 px-4 border border-2 border-branding-1 font-face-sh-bold rounded-pill black-clr text-decoration-none branding-1-hover fs-5 text-center text-white lh-1"
                    >
                      Join Now
                    </Link>
                  </>
                </>
              )}
            </div>
            <div className="col-sm-6 col-md-6 col-6 mobile-menu justify-content-end d-flex align-items-center d-none">
              <div className={Sidebar ? "MenuButton_rootActive" : "MenuButton_root"} onClick={() => setSidebar(!Sidebar)}>
                <div className={Sidebar ? "MenuButton_firstLineActive" : "MenuButton_firstLine"}></div>
                <div className={Sidebar ? "MenuButton_secondLineActive" : "MenuButton_secondLine"}></div>
                <div className={Sidebar ? "MenuButton_thirdLineActive" : "MenuButton_thirdLine"}></div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="mySidebar"
          className={
            Sidebar
              ? "open sidebar bg-light h-100 top-0 end-0 position-fixed d-flex flex-column ease-in-out overflow-hidden py-4 px-4"
              : "sidebar h-100 w-0 end-0 top-0 bg-light position-fixed pt-2 ease-in-out overflow-hidden"
          }
        >
          <div className="logo-red col-md-12">
            <img className="start-0" src={SidebarLogo} alt="logo-white" style={{ width: 150 }} />
          </div>
          <div className="mobile-menu-nav col-md-12 mt-5 d-flex flex-column">
            {login ? (
              <>
                <Link
                  to={"/subscription-plan"}
                  className="text-center mb-3 py-3 px-5 border border-2 border-white rounded-pill bg-branding-1 text-white text-decoration-none fs-6 lh-1 font-face-sh"
                >
                  Enter The Draw
                </Link>
                <div className="fs-6 mb-1" style={{ color: "#999" }}>
                  <strong>Account</strong>
                </div>
                {/* <Link to={"/login"} className='black-clr text-decoration-none fs-5 font-face-sh-bold branding-1-hover'>Login</Link>
          <Link to={"/signup"} className='py-3 px-5 border border-2 border-branding-1 rounded-pill black-clr text-decoration-none branding-1-hover fs-5 mt-3 w-75 text-center lh-1'>Join Now</Link> */}
                <Link to={"/account"} className="black-clr text-decoration-none fs-5 branding-1-hover py-1">
                  My Impact
                </Link>
                <Link to={"/account/prizes"} className="black-clr text-decoration-none fs-5 branding-1-hover py-1">
                  Prizes
                </Link>
                <Link to={"/account/subscription"} className="black-clr text-decoration-none fs-5 branding-1-hover py-1">
                  Manage Subscription
                </Link>
                <Link to={"/account/payment-method"} className="black-clr text-decoration-none fs-5 branding-1-hover py-1">
                  Manage Payment
                </Link>
                <Link to={"/account/details"} className="black-clr text-decoration-none fs-5 branding-1-hover py-1">
                  Settings
                </Link>
                <hr />
                <Link onClick={logout} to={"/"} className="black-clr text-decoration-none fs-5 branding-1-hover">
                  Log Out
                </Link>
              </>
            ) : (
              <>
                <Link to={"/login"} className="black-clr text-decoration-none fs-5 font-face-sh-bold branding-1-hover">
                  Login
                </Link>
                <Link
                  to={"/signup"}
                  className="py-3 px-5 border border-2 border-branding-1 rounded-pill black-clr text-decoration-none branding-1-hover fs-5 mt-3 w-75 text-center lh-1"
                >
                  Sign up
                </Link>
              </>
            )}
          </div>

          <div className="col-sm-12 col-md-12 col-lg-3 mt-auto">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 branding-1">
                <p className="mt-4 pt-2 font-face-sh-bold">Follow us</p>
                <div className="d-flex">
                  <a className="black-clr text-decoration-none" href="https://www.facebook.com/officialkerryellis/" target="_blank">
                    <img className="me-3" src={Facebook} alt="Facebook" style={{ width: 24 }} />
                  </a>
                  <a className="black-clr text-decoration-none" href="https://twitter.com/kerryjaneellis1" target="_blank">
                    <img className="me-3" src={Twitter} alt="Twitter" style={{ width: 24 }} />
                  </a>
                  <a className="black-clr text-decoration-none" href="https://www.instagram.com/kerryellis79/" target="_blank">
                    <img className="me-3" src={Instagram} alt="Instagram" style={{ width: 24 }} />
                  </a>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 branding-1">
                <p className="mt-4 pt-2" style={{ fontSize: 14 }}>
                  The Kerry Ellis Prize Draw is operated by{" "}
                  <a className="text-decoration-none branding-1 branding-1-hover" href="https://playfundwin.com/" target="_blank">
                    Play Fund Win
                  </a>
                </p>
                <div className="d-flex font-face-sh-bold" style={{ fontSize: 14 }}>
                  <Link className="branding-1 text-decoration-none pe-5 branding-1-hover" to={"/terms-conditions"}>
                    Terms & Conditions
                  </Link>
                  <Link className="branding-1 text-decoration-none branding-1-hover" to={"/privacy-policy"}>
                    Privacy Policy
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <section className="hero-section herosec-img font-face-sh py-sm-" style={{marginTop: '-120px', padding: '170px 0 200px'}}>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-12 col-md-7 col-lg-7'>
           <h1 className="text-white lh-1 mb-4 display-1 font-face-sh-bold">IT’S A <br />WIN-WIN!</h1>
           <h3 className="text-white w-75 wt-100 fs-5 lh-2 fw-normal pb-md-5 mb-5 mr-0"><p>Enter our annual prize draw and you could win your dream holiday package, while helping to advance the mental wellness thousands of people working in the advertising, media and marketing community across the UK.</p>
           <p>Simply enter between now and XX DATE, and you could win our fantastic top prize: a Virgin Atlantic/Virgin Holiday worth an amazing £10k!</p></h3>
           <Link to={'/signup'} className="btn text-uppercase bg-branding-green border-0 w-50 rounded-pill py-3 mt-5 lh-1 desktop-btn text-light ">Join Now</Link>
          </div>
          <div className='col-sm-12 col-md-5 col-lg-5 hero-right-block d-flex justify-content-end'>
            <div className='match-card w-100 ms-2'>
              <div className='matchcard-header px-4 py-3 d-flex align-items-center justify-content-between'>
                <p className='text-white fs-5 m-0'>In support of</p>
                <img className='start-0' style={{width: 178}} src={LogoFoundationWhite} alt="LogoFoundationWhite" />
              </div>
              <div className='matchcard-logosblock bg-white text-center p-5' style={{borderBottomLeftRadius: 90}}>
                <p className='branding-2 fs-4'>Winners next announced:</p>
                <div className='d-flex align-items-center justify-content-between py-5 pb-5'>
                  {/* <img className='start-0' style={{width: 100}} src={HeaderLeft} alt="HeaderLeft" />
                  <h2 className="branding-2 lh-1 display-3 font-face-sh-bold">VS</h2> 
                  <img className='start-0' style={{width: '100%'}} src={HeaderRight} alt="BrightonLogo" />
                </div>
                <p className='branding-2 fs-4 pt-2 m-0'>{Moment(DrawData.drawEndDate).format('LLL')}</p>
              </div>
            </div>
          </div>
          <div className='mobile-bottom d-none'>
              <Link to={"/signup"} className="btn bg-branding-green text-uppercase border-0 w-50 rounded-pill py-3 mt-5 lh-1 text-white">Join Now</Link>
            </div>
        </div>
      </div>
    </section> */}

      <section className="hero-section herosec-img font-face-sh py-sm-" style={{ padding: "170px 0 50px", height: "90vh" }}>
        <div className="container">
          <div className="row position-realtive">
            <div className="col-sm-12 col-md-7 col-lg-8 text-left top-title-header" style={{ paddingTop: "0px" }}>
              {/* <h1 className="text-white title-managed position-relative lh-4 w-auto mt-4 mb-0  font-face-sh-bold" style={{zIndex : 9}}>Kerry Ellis Prize Draw</h1> */}
              <div className="date-time d-flex align-items-center pb-4">
                <div className="grid-date-time text-white fs-2 p-2 bg-branding-4">
                  {remainingDays}
                  <span className="fs-4 p-2">Days</span>
                </div>
                <div className="grid-date-time text-white fs-2 p-2 bg-branding-4">
                  {remainingHours}
                  <span className="fs-4 p-2">Hours</span>
                </div>
                <div className="grid-date-time text-white fs-2 p-2 bg-branding-4">
                  {remainingMinutes}
                  <span className="fs-4 p-2">Min</span>
                </div>
              </div>
              <h1 className="w-100 display-5 text-white lh-2 fw-normal my-4 mr-0 font-face-sh-bold">
                <span className="branding-2">WIN</span> EXCLUSIVE VIP PACKAGES <br></br>TO TOP WEST END SHOWS...
              </h1>
              <h3 className="w-100 display-6 font-face-sh-bold text-white lh-2 fw-normal my-4 mr-0">
                Wicked, Frozen, We Will Rock You <br /> and many more!
              </h3>
              {/* <Link to={'/signup'} className="btn border-0 w-auto text-white rounded-pill py-3 px-4 mt-1 lh-1 desktop-btn branding-1" >Enter Draw</Link> */}
            </div>
            {/* <div className='prizes-section-data text-center d-flex border-white border-bottom rounded-top' style={{justifyContent : 'center'}}>
            <div className='prizes-section-subdata'>
              <h3 className="w-auto fs-5 lh-2 p-3 fw-normal mb-0 mr-0 mt-5 branding-1 bg-white" style={{borderTopLeftRadius : 20,borderTopRightRadius : 20}}>WIN! Amazing Prizes!</h3>
            </div>
          </div> */}
            {/* <div className='prizes-sliders my-5 mb-0'>
            <PrizeSlider/>
           
            
          </div> */}

            {/* <div className='mobile-bottom d-none'>
              <Link to={"/signup"} className="btn bg-branding-green text-uppercase border-0 w-50 rounded-pill py-3 mt-5 lh-1 text-white">Join Now</Link>
            </div> */}
          </div>
        </div>
      </section>

      <section className="ticket-data bg-black py-5">
        <div className="container">
          <div className="ticket-section pb-5">
            <div className="py-1 d-flex align-items-center mb-4">
              <img src={require("../images/playicon.png")} />
              <h2 className="mt-2 mx-3">
                <span className="position-relative display-5 branding-2 font-face-sh-bold">ENTER THE DRAW</span>
              </h2>
            </div>
            <p className="fs-5 text-white">How many tickets would you like to buy? £5 per ticket</p>

            {!paymentDetails && SubscriptionPlan ? (
              <section className="subscription-plan font-face-sh">
                <div className="subscription-plan-container mx-auto">
                  {/* <svg viewBox="0 0 1425 358" xmlns="http://www.w3.org/2000/svg" className="StepSubscriptionPlan_topBackground position-fixed top-0 start-0" style={{ width: "100vw", height: "25.12281vw" }}><path d="M1425 .84v207.845c0 197.772-1425 200.397-1425 0V.42l1425 .42z" fill="#bd10e0" fillRule="nonzero" /></svg> */}
                  <div
                    className={Freemodal ? "steps-subscription-plan position-relative pt-5 d-none" : "steps-subscription-plan position-relative pt-5 d-block"}
                    style={{ zIndex: 999 }}
                  >
                    {/* <h2 className='step-subscription-plan-title text-light font-face-sh-bold display-6 text-center mb-3'>Pick your monthly plan</h2> */}
                    {/* <div className='step-subscription-plan-description'>
                      <h3 className='step-subscription-plan-description_title text-light font-face-sh-bold fs-5 text-center mb-0'>How many entries do you want for each draw?</h3>
                      <p className='step-subscription-plan-description_text text-light text-center'>You can cancel or change your plan at any time.</p>
                  </div> */}
                    <div className="step-subscription-plan-selector pt-4">
                      <ul className="d-flex unstyled list-unstyled gap-3">
                        <li className={isActive1 ? "active" : ""} onClick={() => handleClick("first", 5, 1)}>
                          <div className="ticket-title-sec px-3 py-5 position-relative" style={{ borderRadius: 6, filter: "drop-shadow(0 4px 28px rgba(0,0,0,.12))" }}>
                            <h4 className="fs-5 text-center mb-4 mb-0" style={{ color: "#302d51", borderBottom: "2px solid #50e3c2", paddingBottom: 15 }}>
                              1 Ticket
                            </h4>
                            <div className="price text-center posiion-relative d-flex justify-content-center">
                              <span className="display-6">£</span>
                              <span className="display-4">5</span>
                            </div>
                          </div>
                        </li>
                        <li className={isActive2 ? "active" : ""} onClick={() => handleClick("second", 15, 3)}>
                          <div className="ticket-title-sec px-3 py-5 position-relative" style={{ borderRadius: 6, filter: "drop-shadow(0 4px 28px rgba(0,0,0,.12))" }}>
                            <h4 className="fs-5 text-center mb-4 mb-0" style={{ color: "#302d51", borderBottom: "2px solid #50e3c2", paddingBottom: 15 }}>
                              3 Tickets
                            </h4>

                            <div className="price text-center posiion-relative d-flex justify-content-center">
                              <span className="display-6">£</span>
                              <span className="display-4">15</span>
                            </div>
                          </div>
                        </li>
                        <li className={isActive3 ? "position-relative active" : "position-relative"} onClick={() => handleClick("third", 25, 5)}>
                          <div
                            className="subscription-plan-emphasis text-balck bg-branding-4 d-inline-block lh-normal position-absolute start-0 end-0 m-auto"
                            style={{
                              color: "#fff",
                              fontSize: 14,
                              padding: "7px 6px 7px 7px",
                              width: 150,
                              top: -40,
                              zIndex: 999,
                              textAlign: "center",
                              borderTopLeftRadius: 20,
                              borderTopRightRadius: 20,
                            }}
                          >
                            Popular
                          </div>
                          <div className="ticket-title-sec px-3 py-5 position-relative" style={{ borderRadius: 6, filter: "drop-shadow(0 4px 28px rgba(0,0,0,.12))" }}>
                            <h4 className="fs-5 text-center mb-4 mb-0" style={{ color: "#302d51", borderBottom: "2px solid #50e3c2", paddingBottom: 15 }}>
                              5 Tickets
                            </h4>

                            <div className="price text-center posiion-relative d-flex justify-content-center">
                              <span className="display-6">£</span>
                              <span className="display-4">25</span>
                            </div>
                          </div>
                        </li>
                        <li className={isActive4 ? "active" : ""} onClick={() => handleClick("fourth", 50, 10)}>
                          <div className="ticket-title-sec px-3 py-5 position-relative" style={{ borderRadius: 6, filter: "drop-shadow(0 4px 28px rgba(0,0,0,.12))" }}>
                            <h4 className="fs-5 text-center mb-4 mb-0" style={{ color: "#302d51", borderBottom: "2px solid #50e3c2", paddingBottom: 15 }}>
                              10 Tickets
                            </h4>

                            <div className="price text-center posiion-relative d-flex justify-content-center">
                              <span className="display-6">£</span>
                              <span className="display-4">50</span>
                            </div>
                          </div>
                        </li>
                        {/* <li className={isActive5 ? 'active' : ''} onClick={() => handleClick('fifth', 100, 20)}>
                              <div className='ticket-title-sec px-3 py-5 position-relative' style={{ borderRadius: 6, filter: 'drop-shadow(0 4px 28px rgba(0,0,0,.12))' }}>
                              <h4 className='fs-5 text-center mb-4 mb-0' style={{ color: '#302d51', borderBottom : '2px solid #fabc7a', paddingBottom : 15  }}>20 Tickets</h4>
                                 

                                  <div className='price text-center posiion-relative d-flex justify-content-center'>
                                    <span className='display-6'>£</span><span  className='display-4'>100</span>
                                  </div>
                                 
                              </div>
                              
                          </li>
                          <li className={isActive6 ? 'active' : ''} onClick={() => handleClick('sixth', 250, 50)}>
                              <div className='ticket-title-sec px-3 py-5 position-relative' style={{ borderRadius: 6, filter: 'drop-shadow(0 4px 28px rgba(0,0,0,.12))' }}>
                              <h4 className='fs-5 text-center mb-4 mb-0' style={{ color: '#302d51', borderBottom : '2px solid #fabc7a', paddingBottom : 15  }}>50 Tickets</h4>
                                 

                                  <div className='price text-center posiion-relative d-flex justify-content-center'>
                                    <span className='display-6'>£</span><span  className='display-4'>250</span>
                                  </div>
                                 
                              </div>
                              
                          </li> */}
                        {/* <li onClick={() => setFreemodal(!Freemodal)}>
                              <div className='ticket-title-sec px-3 py-5 position-relative other' style={{ borderRadius: 6, filter: 'drop-shadow(0 4px 28px rgba(0,0,0,.12))' }}>
                              <h4 className='fs-5 text-center mb-4 mb-0' style={{ color: '#fff', borderBottom : '2px solid #fabc7a', paddingBottom : 15  }}>5 Tickets</h4>
                                
                                  <div className='price text-center posiion-relative d-flex justify-content-center'>
                                    <span className='display-6 text-white'>£</span><span  className='display-2 text-white'>5</span>
                                  </div>
                              </div>
                            
                          </li> */}
                      </ul>
                    </div>
                    {/* <div className='subscription-plan-selector-amountarea bg-white p-3 mt-4' style={{ borderRadius: 6 }}>
                      <div className='row'>
                          <div className='col-lg-6 col-md-6 col-sm-12'>
                              <div className='subscription-plan-price d-flex justify-content-center'>
                                  <div className='subscription-plan-price_currency branding-1 fs-2 font-face-sh-bold mt-4 me-1'>£</div>
                                  <div className='subscription-plan-price_amount branding-1 display-3 font-face-sh-bold'>{amount}</div>
                                  <div className='subscription-plan-price_term text-black lh-1 mt-3 ms-1' style={{ fontSize: 12 }}>{isoneoff?"":<>per<br/>month</>}</div>
                              </div>
                          </div>
                          <div className='col-lg-6 col-md-6 col-sm-12'>
                              <div className='subscription-plan-selector-entries d-flex align-items-center'>
                                  <div className='draw-entries-icon position-relative' style={{ backgroundColor: '#f3f2fb', width: 52, height: 52, borderRadius: '50%' }}></div>
                                  <ul className='unstyled list-unstyled ul-tick ms-3 mb-0' style={{ fontSize: 12 }}>
                                      <li>Auto-entry into every home draw</li>
                                      <li>Regular charitable support</li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div> */}
                    {/* <div className="form-check form-switch d-flex justify-content-center pt-5">
                          <input checked={isoneoff} onChange={() => setIsoneoff(!isoneoff)} className="form-check-input me-2" type="checkbox" id="flexSwitchCheckDefault" />
                          <label className="form-check-label text-white" htmlFor="flexSwitchCheckDefault">{!isoneoff ? "Tick to make a one-off entry into the next draw" : "untick to make a Subscription entry into the next draw"}</label>
                      </div> */}
                    <div className="terms-cond mt-3 text-center">
                      <p className="m-0 text-white">
                        Alternative entry routes available,{" "}
                        <Link className="text-decoration-none branding-1 branding-1-hover" to={"/terms-conditions"}>
                          click here
                        </Link>{" "}
                        for terms.
                      </p>
                    </div>
                    <div className="step-subscription-button text-center mb-5 d-flex justify-content-center">
                      <button
                        onClick={() => toPaymentDetails(true)}
                        className="btn bg-branding-green border-0 rounded-pill py-2 mt-5 lh-2 desktop-btn text-light position-relative d-flex align-items-center justify-content-center"
                        style={{ width: 400, height: 48 }}
                      >
                        <span>Enter Draw</span>
                        <img className="position-absolute top-0 bottom-0 my-auto" src={NextArrow} style={{ height: 40, right: 4 }} />
                      </button>
                    </div>
                  </div>
                  <div className={Freemodal ? "free-entries-modal d-block position-relative" : "free-entries-modal d-none"}>
                    <div className="free-entry-modal_title_block mb-5 pb-5">
                      <img
                        className="cross bg-white rounded-3 p-1 position-absolute end-0 top-0"
                        src={Cross}
                        style={{ width: 56, boxShadow: "0 4px 16px rgb(20 19 38 / 30%)", cursor: "pointer" }}
                        onClick={() => setFreemodal(!Freemodal)}
                      />
                      <h2 className="free-entry-modal_title text-light font-face-sh-bold display-6 text-center mb-3 mt-5">Free Prize Draw</h2>
                      <p className="free-entry-modal_text text-light text-center fs-5 mx-auto mt-5" style={{ maxWidth: 375 }}>
                        While we encourage contributions to achieve our mission, we understand not everyone may be in a position to contribute.
                      </p>
                    </div>
                    <div className="free-entry-modal_description_block mx-auto mt-5 p-4 bg-white d-flex" style={{ maxWidth: 375 }}>
                      <div className="free-entry-icon">
                        <img src={FreeEntryIcon} style={{ width: 24 }} />
                      </div>
                      <div className="free-entry-description ps-4 fs-14 lh-1">
                        <p>For 5 non-donation entries to the prize draw please post the following information:</p>
                        <p>
                          * First Name
                          <br />
                          * Last Name
                          <br />* Email
                        </p>
                        <p>
                          to: PFW Holdings Ltd
                          <br />
                          Play Fund Win Office #300,
                          <br />4 Blenheim Court,
                          <br />
                          Peppercorn Close,
                          <br />
                          Peterborough,
                          <br />
                          PE1 2DU
                          <br />
                          <br />
                          You will be entered into the prize draws for the month in which we receive your post entries.
                          <br />
                          <br />
                          Note: This will not result in any contributions to the Foundation
                        </p>
                        <div className="emoji-icon text-center pe-5 mt-4 pt-2">
                          <img className="mx-auto" src={require("../images/emoji_icon.png")} style={{ width: 66 }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            ) : paymentDetails ? (
              <section className="personal-details font-face-sh py-5">
                <div className="subscription-plan-container mx-auto" style={{ maxWidth: 780 }}>
                  <div className="row">
                    <div className="col-lg-7 col-md-7 col-sm-12 step-user-details pe-5">
                      <div
                        className="step-plan_step branding-1 rounded-pill text-center mx-auto"
                        style={{ backgroundColor: "#f7f7fd", width: 112, height: 45, lineHeight: "45px" }}
                      >
                        <strong>Step 2 of 2</strong>
                      </div>
                      {/* <h2 className='text-black font-face-sh-bold mt-3 text-center'>Payment details</h2> */}
                      <p className="text-center" style={{ color: "#808080", fontSize: "18px" }}>
                        {isoneoff ? "" : "We’ll take your subscription amount each month."}
                      </p>
                      <form method="post">
                        {/* <label className='mb-2 ps-3' style={{color: '#808080', fontSize: 15}}>Payment method</label> */}
                        {/* <div className='text-light bg-branding-1 rounded-pill text-center mb-4' style={{width: 112, height: 45, lineHeight: '45px', fontSize: 14}}>Bank card</div> */}
                        {/* <input type="text" name="cardnumber" id="cardnumber" placeholder="Card number" className="input mb-3" /> */}
                        <div className="form-checkbox">
                          <ul className="checkbox-list list-unstyled">
                            <li className="checkbox-item mb-4">
                              <label className="input-checkbox d-flex">
                                <input checked={consent1} onChange={() => setConsent1(!consent1)} type="checkbox" name="consentId1" value="1" />
                                <span className="input-checkbox_check"></span>
                                <span className="input-checkbox-label">Yes, I’d like to receive updates about Kerry Ellis via email.</span>
                              </label>
                            </li>
                            <li className="checkbox-item">
                              <label className="input-checkbox d-flex">
                                <input checked={consent2} onChange={() => setConsent2(!consent2)} type="checkbox" name="consentId2" value="10" />
                                <span className="input-checkbox_check"></span>
                                <span className="input-checkbox-label">Yes, Kerry Ellis Foundation can contact me about their work via email.</span>
                              </label>
                            </li>
                            <li className="checkbox-item">
                              <label
                                className="step-payment-details_consent_description mt-5 input-checkbox d-flex"
                                style={{ backgroundColor: "rgb(198,29,35,.1)", color: "#302d51", padding: "14px 18px", borderRadius: 20, fontSize: 13 }}
                              >
                                <input checked={consent3} onChange={() => setConsent3(!consent3)} type="checkbox" name="consentId3" value="10" />
                                <span className="input-checkbox_check"></span>
                                <span>
                                  By clicking on Confirm you agree to Kerry Ellis Draw{" "}
                                  <Link to={"/terms-conditions"} style={{ color: "#302d51" }}>
                                    Terms & Conditions of Use
                                  </Link>
                                  ,{" "}
                                  <Link to={"/privacy-policy"} style={{ color: "#302d51" }}>
                                    Privacy Policy
                                  </Link>{" "}
                                  and that you <strong>are at least 18 years old.</strong>
                                </span>
                              </label>
                            </li>
                          </ul>
                        </div>
                      </form>
                      {!consent3 ? (
                        <button
                          onClick={checkConsents}
                          className="btn bg-branding-green border-0 rounded-pill py-2 mt-5 lh-2 desktop-btn text-light position-relative d-flex align-items-center justify-content-center"
                          style={{ width: "100%", height: 48 }}
                        >
                          Pay now
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setStripe(true);
                            setOneShow(true);
                          }}
                          className="btn bg-branding-green border-0 rounded-pill py-2 mt-5 lh-2 desktop-btn text-light position-relative d-flex align-items-center justify-content-center"
                          style={{ width: "100%", height: 48 }}
                        >
                          Pay now
                        </button>
                      )}
                    </div>
                    <div className="col-lg-5 col-md-5 col-sm-12 step-user-details-info mt-5 pt-4 pe-0">
                      <div className="user-details-info bg-branding-4 rounded-4" style={{ padding: "35px 35px 19px", boxShadow: "0 15px 50px -30px #000000" }}>
                        <h3 className="text-light fs-5 mb-4">Summary</h3>
                        <div className="summary-info-impact pb-3">
                          <div className="summary-info-impact_title text-light d-flex align-items-center" style={{ fontSize: 18 }}>
                            {entries} chances to win
                          </div>
                          {/* <div className='summary-info-impact_period lh-1' style={{ color: 'rgb(255,255,255,0.7)', paddingLeft: 35 }}>every draw</div> */}
                        </div>
                        <hr className="bg-light w-100 border-0" style={{ height: 1, opacity: 0.1 }} />
                        <div className="summary-info-price d-flex align-items-center pb-2">
                          <span className="summary-info-price_amount text-light display-4">£ {amount}</span>
                          <span className="summary-info-price_period ps-3" style={{ color: "rgb(255,255,255,0.7)" }}>
                            {isoneoff ? "" : "per month"}
                          </span>
                        </div>
                        <hr className="bg-light w-100 border-0" style={{ height: 1, opacity: 0.1 }} />
                        {/* <p className='summary-info-upgrade-text m-0' style={{ color: 'rgb(255,255,255,0.7)' }}>Upgrade or cancel anytime</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            ) : (
              ""
            )}
          </div>
        </div>
      </section>

      <section className="stories-section-title font-face-sh">
        <div className="stories-section bg-black">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h3
                  className="text-center text-white bg-branding-4 mb-3"
                  style={{
                    fontSize: 24,
                    padding: "10px",
                    width: 450,
                    margin: "0 auto",
                    top: -40,
                    zIndex: 999,
                    textAlign: "center",
                    borderTopLeftRadius: 20,
                    borderTopRightRadius: 20,
                  }}
                >
                  Supporting Amazing Charities
                </h3>
                <div className="rounded-4 bg-white">
                  <div className="grid-data-stories">
                    <div className="grid-item-stories">
                      {" "}
                      <img className="mx-auto" src={require("../images/Acting.png")} style={{ width: "100%" }} />
                    </div>
                    <div className="grid-item-stories">
                      {" "}
                      <img className="mx-auto" src={require("../images/Theatres.png")} style={{ width: "100%" }} />
                    </div>
                    <div className="grid-item-stories">
                      {" "}
                      <img className="mx-auto" src={require("../images/mad.png")} style={{ width: "100%" }} />
                    </div>
                    <div className="grid-item-stories">
                      {" "}
                      <img className="mx-auto" src={require("../images/cancers-uk.png")} style={{ width: "100%" }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mission-section font-face-sh bg-black py-5" style={{ zIndex: 99, position: "relative" }}>
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-5">
              <div className="description-datas py-5">
                <p className="fs-5 text-white">
                  Kerry Ellis is a dynamo and an inspiration! She's a multi-award winning West End star, with roles in mesmerizing musicals such as 'Les Misérables', 'Wicked' and
                  'We Will Rock You'. On top of her theatrical success, she's an outstanding accomplished recording artist-releasing albums like the beautiful 'Anthems' and the
                  'Golden Days'. With the voice of an angel, she wows audiences everywhere she performs. An amazing talent that never fails to impress!
                </p>
              </div>
            </div>
            <div className="col-lg-7">
              <img className="mx-auto" src={require("../images/Kerry-Ellis-Feature.png")} style={{ width: "100%" }} />
            </div>
          </div>
        </div>
      </section>

      <section className="prizes-section banners-prizes bg-black position-realtive font-face-sh">
        <div className="container py-1">
          <div className="row">
            <h3 className="w-100 display-5 text-white lh-2 fw-normal my-4 mr-0 font-face-sh-bold">PRIZES INCLUDE</h3>
          </div>
        </div>
        <PrizeSlider />
      </section>

      {/* <section className='banners-section position-relative font-face-sh p-5' style={{backgroundImage: `linear-gradient(to right, #a341d9 ,#54dbc3)` , height : '600px'}}>
      <div className='py-1 p-5 position-relative'>
        <div className='win-title'>
      <h3 className='text-white its-win' style={{fontSize : '180px'}}>IT’S <span style={{color: '#fff0',textShadow: `-1px -1px 0 #000, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff`}}>WIN</span> WIN</h3>
      </div>
      <div className='win-image'>
      <img className='mx-auto' src={require('../images/win-win-images.png')} />

      </div>
      <div className='description-data py-5' style={{position: "relative"}}>
      <div className='row'>
<div className='col-md-6'>
<div className='row'>
        <div className='col-lg-5'>£100k</div><div className='col-lg-2'>|</div><div className='col-lg-5'>04</div>
      </div>
</div>

      </div>
     
      </div>
      </div>
      </section> */}

      <section
        className="mission-section font-face-sh"
        style={{ paddingTop: 360, backgroundImage: "linear-gradient(to right, #a341d9 ,#54dbc3)", zIndex: 99, position: "relative" }}
      >
        <div className="container">
          <h3 className="text-white its-win font-face-sh-bold" style={{ fontSize: "140px", marginBottom: "-50px" }}>
            IT’S <span>WIN</span> WIN
          </h3>
          <div className="row">
            <div className="col-lg-6 position-relative">
              {/* <h3 className='text-white pb-5'>Fund the critical services our industry community relies on.</h3> */}
              <div className="description-data py-5 align-items-center" style={{ position: "relative" }}>
                <div className="row tagets pb-5" style={{ paddingTop: 160 }}>
                  <div className="col-lg-5 display-1 text-center">
                    £100k
                    <br />
                    <span className="fs-5">TARGET</span>
                  </div>
                  <div className="col-lg-2 display-1 text-center">|</div>
                  <div className="col-lg-5 display-1 text-center">
                    4<br />
                    <span className="fs-5">AMAZING CHARITIES</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <img className="mx-auto" src={require("../images/win-win-images.png")} style={{ position: "absolute", top: "5%", zIndex: "-1" }} />
            </div>
          </div>
        </div>
      </section>

      <section className="banners-Kerry Ellis">
        <div className="container py-5">
          <div className="row">
            <p className="p-0">
              <strong>A little information on our chosen charities</strong>
            </p>
            <div className="col-lg-5 p-0 m-0">
              <p>
                <strong>Theatres Trust</strong> works hard to protect, maintain and improve the UK's theatres for everyone to enjoy. This includes providing guidance on how best to
                preserve theatre architecture, investing in new technologies, advocating for sustained funding and supporting theatre education.
              </p>
              <p>
                <strong>Make A Difference [MAD]</strong> is committed to empowering young people from all backgrounds who want to pursue a career in theatre by offering them
                bursaries, educational experience, workshops and other resources.
              </p>
            </div>
            <div className="col-lg-1 p-0 m-0"></div>
            <div className="col-lg-5 p-0 m-0">
              <p>
                <strong>Acting for Others</strong> collects donations from members of the theatrical community which are used to help those in greatest need.{" "}
              </p>
              <p>
                <strong>Cancer Research Trust</strong> dedicates itself to fund vital research into cancer treatments that have saved countless lives. By investing in and
                supporting these charities, not only will we be contributing financially but also raising awareness of the good work they are doing which is absolutely essential.
                We can feel confident that our support will make a real difference in people's lives!
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="how-it-works_section font-face-sh bg-position py-5 position-relative" style={{ marginTop: -10 }}>
        <div className="container p-5">
          <div className="how-it-works_title text-left">
            <h2 className="text-left mb-5 mt-2 pb-2 d-inline-block font-face-sh-bold " style={{ color: "#fff" }}>
              HOW <br />
              <span className="branding-2">IT</span> WORKS
            </h2>
          </div>
          <div className="row ">
            <div className="col-md-12 step1 position-relative">
              <div className="row">
                <div className="col-md-6">
                  <img className="mx-auto" width="100%" src={require("../images/step1.png")} />
                </div>
                <div className="col-md-6">
                  <h3 className="branding-2 py-4 font-face-sh-bold">STEP 01</h3>
                  <div className="border border-bottom"></div>
                  <p className="py-4 fs-5 text-white">
                    It's easy to take part in my charity draw and help to make a huge difference! Simply choose how much you'd like to enter from £5 onwards and follow the simple
                    process.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-12 step2 position-relative py-5">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <h3 className="branding-4 py-4 font-face-sh-bold">STEP 02</h3>
                  <div className="border border-bottom"></div>
                  <p className="py-4 fs-5 text-black">
                    Once you have chosen your donation amount simple follow the payment process, then you'll be entered into the draw for a chance of winning one of the amazing
                    prizes listed below! At the same time, you'll be supporting incredible causes who are doing remarkable work in their respective fields. Participating is an easy
                    way of making a difference, so why not give it a go today?
                  </p>
                </div>
                <div className="col-md-6">
                  <img className="mx-auto" width="100%" src={require("../images/step2.png")} />
                </div>
              </div>
            </div>

            <div className="col-md-12 step3 position-relative py-5">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <img className="mx-auto" width="100%" src={require("../images/step3.png")} />
                </div>
                <div className="col-md-6">
                  <h3 className="branding-2 py-4 font-face-sh-bold">STEP 03</h3>
                  <div className="border border-bottom"></div>
                  <ul className="py-5">
                    <li className="fs-5">Tickets for my tour and VIP pass to soundcheck</li>
                    <li className="fs-5">Studio session with me in London</li>
                    <li className="fs-5">Signed Merch</li>
                    <li className="fs-5">Training session with my trainer Leanne Marshall</li>
                    <li className="fs-5">Choir session with me and a chance to perform together</li>
                    <li className="fs-5">Westend show tickets with meet and greet with the stars We Will Rock You, Wicked, and Frozen</li>
                    <li className="fs-5">Press night Tickets to We Will Rock You</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* <div className='how-it-works-button text-center'>
            <Link to={'/signup'} className="btn bg-white border-0 w-auto rounded-pill py-3 px-4 mt-1 lh-1 desktop-btn" style={{ color: '#000' }}>Find out more</Link></div> */}
        </div>
      </section>

      <section className="cause_section font-face-sh">
        <div className="container p-5">
          <h3 className="text-center">Supporting Amazing Charities</h3>
          <div className="grid-data-stories">
            <div className="grid-item-stories">
              {" "}
              <img className="mx-auto" src={require("../images/Acting.png")} style={{ width: "100%" }} />
            </div>
            <div className="grid-item-stories">
              {" "}
              <img className="mx-auto" src={require("../images/Theatres.png")} style={{ width: "100%" }} />
            </div>
            <div className="grid-item-stories">
              {" "}
              <img className="mx-auto" src={require("../images/mad.png")} style={{ width: "100%" }} />
            </div>
            <div className="grid-item-stories">
              {" "}
              <img className="mx-auto" src={require("../images/cancers-uk.png")} style={{ width: "100%" }} />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid pt-3 pb-4 px-0 how-it-works fon-face-gb" style={{ backgroundColor: "#f2f2f2" }}>
          <div className="container py-5 pt-2">
            <div className="logo-bottom text-center py-5">
              <Link to={"/"}>
                <img src={LogoBottom} style={{ width: "450px" }} />
              </Link>
            </div>

            <div className="text-center d-flex" style={{ justifyContent: "center" }}>
              <div className="data-title w-75">
                <h2 className="hiw-title branding1 text-center font-face-gb-semibold fs-2 mb-5">
                  The revolutionary digital fundraising platform that makes raising money simple for charities, celebrities and amazing causes
                </h2>
              </div>
            </div>

            <div className="col-md-4 borders-datas"></div>

            <div className="row pt-3">
              <div className="col-md-4 px-5">
                <div className="py-3 d-flex align-items-center justify-content-center">
                  <img src={require("../images/play-black.png")} />
                  <h2 className="branding1 mn-0 ms-4 font-face-gb-semibold display-4">play</h2>
                </div>
                <p className="text-center fs-4">Play our draws</p>
              </div>
              <div className="col-md-4 px-5">
                <div className="py-3 d-flex align-items-center justify-content-center">
                  <img src={require("../images/fund-black.png")} />
                  <h2 className="text-left branding1 mb-0 ms-4 font-face-gb-semibold display-4">fund</h2>
                </div>
                <p className="text-center fs-4">Fund good causes</p>
              </div>
              <div className="col-md-4 px-5">
                <div className="py-3 d-flex align-items-center justify-content-center">
                  <img src={require("../images/win-black.png")} />
                  <h2 className="text-left branding1 mb-0 ms-4 font-face-gb-semibold display-4">win</h2>
                </div>
                <p className="text-center fs-4">Win amazing prizes</p>
              </div>
            </div>
            <p className="text-center mt-4 pt-2">If you wish to start your fundraising journey with Play Fund Win then simply get in touch !</p>
            <div className="text-center">
              <a
                href="https://playfundwin.com/help-centre"
                target="_blank"
                className="border-0 rounded-pill btn bg-branding-1 text-black secbutton py-2 px-5 mt-4 mb-2 font-face-gb-semibold"
                style={{ lineHeight: 2 }}
              >
                Speak to us
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* <section className='charity_section font-face-sh mb-5 pb-5'>
      <div className='container-2'>
        <div className='charity_title text-center py-5 px-md-5'>
          <h2 className='display-4 text-uppercase text-center px-md-5 lh-1 font-face-sh-bold' style={{color : '#fabc7a'}}>Kerry Ellis</h2>
          <p className='branding-1 fs-5 lh-1 mt-4 w-50 m-auto'>Building better lives through sport</p>
          <p className='branding-1 fs-6  lh-1 mt-5'>Read about our work</p>
          <div style={{marginTop: -35}}>
            <img src={Arrow} alt="Arrow" />
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-6 pe-5'>
            <p className='branding-3 fs-5 lh-2'>Enter our annual prize draw and you could win your dream holiday package, while helping to advance the mental wellness thousands of people working in the advertising, media and marketing community across the UK. </p>

            <p className='branding-3 fs-5 lh-2'>Simply enter between now and XX DATE, and you could win our fantastic top prize: a Virgin Atlantic/Virgin Holiday worth an amazing £10k! </p>

            <p className='branding-3 fs-5 lh-2'>Money raised through the Kerry Ellis prize draw goes to your industry charity, to provide essential support services from our empathetic Advice Line to our life-changing grants. And, on top of that, you’ll be in with a chance of winning your dream holiday! </p>

            <p className='branding-3 fs-5 lh-2'>Calls to our Advice Line rose by 35% last year – the top two reasons people came to us were for emotional and financial support, and access to our redundancy support soared by 1432%. As our community braces for another challenging year, Kerry Ellis’ support has never been more vital.  </p>

            <p className='branding-3 fs-5 lh-2'>Play today and help people across your industry community to get back on their feet and thriving again. </p>

            <p className='branding-3 fs-5 lh-2'>Entry costs just £5 and you can buy multiple tickets to increase your chance of winning. </p>

            <p className='branding-3 fs-5 lh-2'>Thank you for your support. </p>
           
            <Link to={'/signup'} className="signup-btn btn bg-branding-1 shadow-lg text-white border-0 w-50 mb-5 rounded-pill py-3 mt-5 lh-1">Find out more</Link>
          </div>
          <div className='col-sm-12 col-md-12 col-lg-6'>
            <div className='row'>
              <div className='col-sm-6 col-md-12 col-lg-6 col-6 pe-4'>
                <img className='w-100 rounded-3' src={require('../images/LG-TV.jpg')} alt="charity-image1" style={{height : 150, objectFit : 'cover'}}/>
              </div>
              <div className='col-sm-6 col-md-12 col-lg-6 col-6 ps-4'>
                <img className='w-100 rounded-3' src={require('../images/tickets-prize.png')} alt="charity-image2" style={{height : 150, objectFit : 'cover'}}/>
              </div>
            </div>
            <div className='row pt-4 mt-3'>
              <div className='col-sm-12 col-md-12 col-lg-12'> */}
      {/* <img className='w-100 rounded-3' src={require('../images/Kerry Ellis_Martins_Story_Short3_vs1.mp4')} alt="charity-image3"/> */}
      {/* <iframe className="video-desc" src={"http://pfw.storage.bucket.s3-website.eu-west-2.amazonaws.com/video/Kerry Ellis_Martins_Story_Short3_vs1.2b5402900bc37049dab7.mp4"} title="Martins Story" width='100%' height="500px" allowfullscreen></iframe>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section> */}

      <section>
        {/* <div className='draw-entry position-fixed px-3 py-3 bottom-0 bg-branding-1 w-100' style={{zIndex : 9999999 }}> */}

        {!paymentDetails && SubscriptionPlan ? (
          <section
            className="subscription-plan-home font-face-sh draw-entry position-fixed px-3 py-3 bottom-0 bg-black w-100"
            style={{ zIndex: 999, backgroundImage: `linear-gradient(to right, #a341d9 ,#54dbc3)` }}
          >
            <div className="row align-items-center">
              <div className="col-sm-9 col-md-9 col-lg-9 col-9">
                <h1 className="text-light">Enter the draw</h1>
                <p className="text-light">How many tickets would you like to buy?</p>
              </div>
              <div
                className="col-sm-3 col-md-3 col-lg-3 col-3 text-center bg-branding-2"
                style={{ backgroundColor: "#000", borderBottomLeftRadius: 70, borderBottomRightRadius: 70, padding: "14px 0px", marginTop: "-23px" }}
              >
                <h4 className="text-light">Total</h4>
                <h4 className="text-light d-flex justify-content-center align-items-center">
                  <div className="subscription-plan-price_currency text-light fs-2 font-face-sh-bold mt-4 me-1">£</div>
                  <div className="subscription-plan-price_amount text-light display-3 font-face-sh-bold">{amount}</div>
                </h4>
              </div>
            </div>
            <div className="subscription-plan-container mx-auto" style={{ maxWidth: 700 }}>
              <div
                className={Freemodal ? "steps-subscription-plan px-0 position-relative pt-5 d-none" : "steps-subscription-plan px-0 position-relative d-block"}
                style={{ zIndex: 999 }}
              >
                <div className="ticket-entry">
                  {/* <div className="form-check form-switch">
                            <input checked={isoneoff} onChange={() => setIsoneoff(!isoneoff)} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                            <label className="form-check-label text-white" htmlFor="flexSwitchCheckDefault">{!isoneoff?"Tick to make a one-off entry into the next draw":"untick to make a Subscription entry into the next draw"}</label>
                          </div>
                         */}

                  <div className="row mt-3">
                    <div className="col-sm-6 col-md-6 col-lg-6 col-6">
                      <div className="step-subscription-plan-selector home-data">
                        <ul className="d-flex unstyled list-unstyled">
                          <li className={isActive1 ? "active" : ""} onClick={() => handleClick("first", 5, 1)}>
                            <strong
                              className="ticket-price d-flex text-black justify-content-center align-items-center branding1 rounded-circle"
                              style={{ backgroundColor: "#fff", width: 42, height: 42, alignItems: "center", justifyContent: "center", display: "flex" }}
                            >
                              <div className="subscription-price fs-5 d-none">
                                <strong>£5</strong>
                              </div>
                              <div className="subscription-period fs-6 d-none">{!isoneoff ? "/month" : ""}</div>1
                            </strong>
                          </li>

                          <li className={isActive2 ? "active" : ""} onClick={() => handleClick("second", 15, 3)}>
                            <strong
                              className="ticket-price d-flex text-black justify-content-center align-items-center branding1 rounded-circle"
                              style={{ backgroundColor: "#fff", width: 42, height: 42, alignItems: "center", justifyContent: "center", display: "flex" }}
                            >
                              <div className="subscription-price fs-5 d-none">
                                <strong>£15</strong>
                              </div>
                              <div className="subscription-period fs-6 d-none">{!isoneoff ? "/month" : ""}</div>3
                            </strong>
                          </li>
                          <li className={isActive3 ? "position-relative  active" : "position-relative"} onClick={() => handleClick("third", 25, 5)}>
                            <strong
                              className="ticket-price d-flex text-black justify-content-center align-items-center branding1 rounded-circle"
                              style={{ backgroundColor: "#fff", width: 42, height: 42, alignItems: "center", justifyContent: "center", display: "flex" }}
                            >
                              <div className="subscription-price fs-5 d-none">
                                <strong>£25</strong>
                              </div>
                              <div className="subscription-period fs-6 d-none">{!isoneoff ? "/month" : ""}</div>5
                            </strong>
                          </li>
                          <li className={isActive4 ? "active" : ""} onClick={() => handleClick("fourth", 50, 10)}>
                            <strong
                              className="ticket-price d-flex text-black justify-content-center align-items-center branding1 rounded-circle"
                              style={{ backgroundColor: "#fff", width: 42, height: 42, alignItems: "center", justifyContent: "center", display: "flex" }}
                            >
                              <div className="subscription-price fs-5 d-none">
                                <strong>£50</strong>
                              </div>
                              <div className="subscription-period fs-6 d-none">{!isoneoff ? "/month" : ""}</div>
                              10
                            </strong>
                          </li>
                          {/* <li className={isActive5 ? 'active' : ''} onClick={() => handleClick('fifth', 100, 20)}>

                            <strong className='ticket-price d-flex branding-1 justify-content-center align-items-center branding1 rounded-circle' style={{ backgroundColor: '#fff', width: 42, height: 42, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                              <div className='subscription-price fs-5 d-none'><strong>£100</strong></div>
                              <div className='subscription-period fs-6 d-none'>{!isoneoff ? "/month" : ""}</div>
                              20
                            </strong>
                          </li>
                          <li className={isActive6 ? 'active' : ''} onClick={() => handleClick('sixth', 250, 50)}>

                            <strong className='ticket-price d-flex branding-1 justify-content-center align-items-center branding1 rounded-circle' style={{ backgroundColor: '#fff', width: 42, height: 42, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                              <div className='subscription-price fs-5 d-none'><strong>£250</strong></div>
                              <div className='subscription-period fs-6 d-none'>{!isoneoff ? "/month" : ""}</div>
                              50
                            </strong>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6 col-6">
                      <div className="step-subscription-button text-center d-flex justify-content-center">
                        <button
                          onClick={() => toPaymentDetails(true)}
                          className="btn bg-branding-green border-0 rounded-pill py-2 lh-2 desktop-btn text-light position-relative d-flex align-items-center justify-content-center"
                          style={{ width: 400, height: 48 }}
                        >
                          <span>{!isoneoff ? "Subscribe" : "Buy Now"}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Freemodal ? "free-entries-modal d-block position-relative" : "free-entries-modal d-none"}>
                <div className="free-entry-modal_title_block mb-5 pb-5">
                  <img
                    className="cross bg-white rounded-3 p-1 position-absolute end-0 top-0"
                    src={Cross}
                    style={{ width: 56, boxShadow: "0 4px 16px rgb(20 19 38 / 30%)", cursor: "pointer" }}
                    onClick={() => setFreemodal(!Freemodal)}
                  />
                  <h2 className="free-entry-modal_title text-light font-face-sh-bold display-6 text-center mb-3 mt-5">Free Prize Draw</h2>
                  <p className="free-entry-modal_text text-light text-center fs-5 mx-auto mt-5" style={{ maxWidth: 375 }}>
                    While we encourage contributions to achieve our mission, we understand not everyone may be in a position to contribute.
                  </p>
                </div>
                <div className="free-entry-modal_description_block mx-auto mt-5 p-4 bg-white d-flex" style={{ maxWidth: 375 }}>
                  <div className="free-entry-icon">
                    <img src={FreeEntryIcon} style={{ width: 24 }} />
                  </div>
                  <div className="free-entry-description ps-4 fs-14 lh-1">
                    <p>For 5 non-donation entries to the prize draw please post the following information:</p>
                    <p>
                      * First Name
                      <br />
                      * Last Name
                      <br />* Email
                    </p>
                    <p>
                      to: PFW Holdings Ltd
                      <br />
                      Play Fund Win Office #300,
                      <br />4 Blenheim Court,
                      <br />
                      Peppercorn Close,
                      <br />
                      Peterborough,
                      <br />
                      PE1 2DU
                      <br />
                      <br />
                      You will be entered into the prize draws for the month in which we receive your post entries.
                      <br />
                      <br />
                      Note: This will not result in any contributions to the Foundation
                    </p>
                    <div className="emoji-icon text-center pe-5 mt-4 pt-2">
                      <img className="mx-auto" src={require("../images/emoji_icon.png")} style={{ width: 66 }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : paymentDetails && !oneShow ? (
          <section
            className="personal-details font-face-sh py-5 position-fixed"
            style={{ backgroundColor: "#fff", left: 0, right: 0, top: 0, bottom: 0, zIndex: 999999, overflow: "scroll" }}
          >
            <a className="black-clr text-decoration-none" href="/">
              <img className="mx-3" src={require("../images/back.png")} style={{ width: 32 }} />
            </a>
            <div className="subscription-plan-container mx-auto" style={{ maxWidth: 780 }}>
              <div className="row">
                <div className="col-lg-7 col-md-7 col-sm-12 step-user-details pe-5">
                  <div className="step-plan_step branding-1 rounded-pill text-center mx-auto" style={{ backgroundColor: "#f7f7fd", width: 112, height: 45, lineHeight: "45px" }}>
                    <strong>Step 2 of 2</strong>
                  </div>
                  {/* <h2 className='text-black font-face-sh-bold mt-3 text-center'>Payment details</h2> */}
                  <p className="text-center" style={{ color: "#808080", fontSize: "18px" }}>
                    {isoneoff ? "" : "We’ll take your subscription amount each month."}
                  </p>
                  <form method="post">
                    {/* <label className='mb-2 ps-3' style={{color: '#808080', fontSize: 15}}>Payment method</label> */}
                    {/* <div className='text-light bg-branding-1 rounded-pill text-center mb-4' style={{width: 112, height: 45, lineHeight: '45px', fontSize: 14}}>Bank card</div> */}
                    {/* <input type="text" name="cardnumber" id="cardnumber" placeholder="Card number" className="input mb-3" /> */}
                    <div className="form-checkbox">
                      <ul className="checkbox-list list-unstyled">
                        <li className="checkbox-item mb-4">
                          <label className="input-checkbox d-flex">
                            <input checked={consent1} onChange={() => setConsent1(!consent1)} type="checkbox" name="consentId1" value="1" />
                            <span className="input-checkbox_check"></span>
                            <span className="input-checkbox-label">Yes, I’d like to receive updates about Kerry Ellis via email.</span>
                          </label>
                        </li>
                        <li className="checkbox-item">
                          <label className="input-checkbox d-flex">
                            <input checked={consent2} onChange={() => setConsent2(!consent2)} type="checkbox" name="consentId2" value="10" />
                            <span className="input-checkbox_check"></span>
                            <span className="input-checkbox-label">Yes, Kerry Ellis can contact me about their work via email.</span>
                          </label>
                        </li>
                        <li className="checkbox-item">
                          <label
                            className="step-payment-details_consent_description mt-3 input-checkbox d-flex"
                            style={{ backgroundColor: "rgb(198,29,35,.1)", color: "#302d51", padding: "14px 18px", borderRadius: 20, fontSize: 13 }}
                          >
                            <input checked={consent3} onChange={() => setConsent3(!consent3)} type="checkbox" name="consentId3" value="10" />
                            <span className="input-checkbox_check"></span>
                            <span>
                              By clicking on Confirm you agree to Kerry Ellis Draw{" "}
                              <Link to={"/terms-conditions"} style={{ color: "#302d51" }}>
                                Terms & Conditions of Use
                              </Link>
                              ,{" "}
                              <Link to={"/privacy-policy"} style={{ color: "#302d51" }}>
                                Privacy Policy
                              </Link>{" "}
                              and that you <strong>are at least 18 years old.</strong>
                            </span>
                          </label>
                        </li>
                        <li className="checkbox-item pt-4 pb-0">
                          <input
                            type="text"
                            name="fullName"
                            id="fullName"
                            placeholder="Full Name"
                            value={fullName}
                            onChange={(event) => setFullName(event.target.value)}
                            className="input mb-3"
                          />
                          {/* <input type="text" id="firstname" value={userFirstName} onChange={(event) => setFirstName(event.target.value)} placeholder="Enter first name" className="input" style={{ display: 'block' }} />
                            <input type="text" id="lastname" value={userLastName} onChange={(event) => setLastName(event.target.value)} placeholder="Enter last name" className="input" style={{ display: 'block' }} /> */}
                          <input
                            type="email"
                            id="email"
                            value={userEmail}
                            onChange={(event) => setEmail(event.target.value)}
                            placeholder="Email address"
                            className="input"
                            style={{ display: "block" }}
                          />
                          {/* <input type="tel" value={dob} onInput={(event) => dateSet(event.target.value, event)} name="dateOfBirth" id="dateOfBirth" placeholder="Date of birth (DD/MM/YYYY)" className="input mb-2" /> */}
                          {/* <div className='dob-field'>
                                <input type="date" id={"donb"} value={dob} onFocus={(e)=> {e.currentTarget.type = "date";e.currentTarget.focus();}} onInput={(event) => { setDob(event.target.value, event); dateSet(event.target.value, event)}} name="dateOfBirth" className='input mb-2' /> 
                                <label className='mb-2' htmlFor='donb' style={{ color: '#818d9d', fontSize: 16 }}>{dob?dob:"Date of birth (DD/MM/YYYY)"}</label>
                            </div> */}
                          <div className="form-radio-box mb-4">
                            <label className="mb-2" style={{ color: "#808080", fontSize: 14 }}>
                              Gender
                            </label>
                            <ul className="radio-list list-unstyled d-flex gap-3">
                              <li className="radio-item">
                                <label className="input-radio">
                                  <input type="radio" name="gender" value="1" defaultChecked onClick={() => setGender(1)} />
                                  <span className="input-radio-label">Male</span>
                                </label>
                              </li>
                              <li className="radio-item">
                                <label className="input-radio">
                                  <input type="radio" name="gender" value="2" onClick={() => setGender(2)} />
                                  <span className="input-radio-label">Female</span>
                                </label>
                              </li>
                              <li className="radio-item">
                                <label className="input-radio">
                                  <input type="radio" name="gender" value="3" onClick={() => setGender(3)} />
                                  <span className="input-radio-label">Other</span>
                                </label>
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </form>
                  <button
                    onClick={checkConsents}
                    className="btn bg-branding-green border-0 rounded-pill py-2 mt-3 lh-2 desktop-btn text-light position-relative d-flex align-items-center justify-content-center"
                    style={{ width: "100%", height: 48 }}
                  >
                    Pay now
                  </button>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-12 step-user-details-info mt-5 pt-4 pe-0">
                  <div className="user-details-info bg-branding-4 rounded-4" style={{ padding: "35px 35px 19px", boxShadow: "0 15px 50px -30px #000000" }}>
                    <h3 className="text-light fs-5 mb-4">Summary</h3>
                    <div className="summary-info-impact pb-3">
                      <div className="summary-info-impact_title text-light d-flex align-items-center" style={{ fontSize: 18 }}>
                        {entries} chances to win
                      </div>
                      {/* <div className='summary-info-impact_period lh-1' style={{ color: 'rgb(255,255,255,0.7)', paddingLeft: 35 }}>every draw</div> */}
                    </div>
                    <hr className="bg-light w-100 border-0" style={{ height: 1, opacity: 0.1 }} />
                    <div className="summary-info-price d-flex align-items-center pb-2">
                      <span className="summary-info-price_amount text-light display-4">£ {amount}</span>
                      <span className="summary-info-price_period ps-3" style={{ color: "rgb(255,255,255,0.7)" }}>
                        {isoneoff ? "" : "per month"}
                      </span>
                    </div>
                    <hr className="bg-light w-100 border-0" style={{ height: 1, opacity: 0.1 }} />
                    {/* <p className='summary-info-upgrade-text m-0' style={{ color: 'rgb(255,255,255,0.7)' }}>Upgrade or cancel anytime</p> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          ""
        )}
        {isStripe ? (
          <Modal show={oneShow} onHide={() => setOneShow(false)} size="md" centered>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <Elements stripe={stripePromise}>
                <StripeForm
                  UserID={UserID}
                  oneoff={isoneoff}
                  show={true}
                  email={userEmail}
                  name={fullName}
                  address1="-"
                  address2="-"
                  postcode="-"
                  amount={amount}
                  entries={entries}
                  hideModal={hideModal}
                  navigate={navigate}
                  timeout={timeout}
                />
              </Elements>
            </Modal.Body>
          </Modal>
        ) : (
          ""
        )}
        <ToastContainer />

        {/* </div> */}
      </section>

      <footer className="footer-section font-face-sh py-5 position-relative home-footer new-footer bg-black">
        <div className="containers px-5">
          <div className="row py-4 border-bottom">
            <div className="col-sm-12 col-md-12 col-lg-3 black-clr">
              <div className="footer-logo">
                <img className="" src={LogoFoundationGray} alt="logo-foundation-gray" style={{ width: 150 }} />
              </div>

              {/* <h4 className='mb-4 pb-2 pt-2 font-face-sh-bold'>Contact us</h4>
              <div className='footer-address d-flex align-items-start'>
                <img className='me-3' src={FooterIconAddress} alt="FooterIconAddress" style={{ width: 24 }} />
                <p style={{ fontSize: 14 }}>Post: PFW Holdings Ltd Suite#300, 4 Blenheim Court, Peppercorn Close, Peterborough, PE1 2DU</p>
              </div>
              <div className='footer-email d-flex align-items-start'>
                <img className='me-3' src={FooterIconEmail} alt="FooterIconEmail" style={{ width: 24 }} />
                <p style={{ fontSize: 14 }}>Support: <a href="mailto:hello@playfundwin.com" className='text-decoration-none black-clr branding-1-hover'>hello@playfundwin.com</a></p>
              </div> */}
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <div className="row">
                {/* <div className='col-sm-12 col-md-12 col-lg-6 black-clr mb-3'>
                  <p className='mt-4 pt-2 font-face-sh-bold'>Follow us</p>
                  <div className='d-flex'>
                    <a className="black-clr text-decoration-none" href="https://www.facebook.com/officialkerryellis/" target='_blank'><img className="me-3" src={Facebook} alt="Facebook" style={{ width: 24 }} /></a>
                    <a className='black-clr text-decoration-none' href="https://twitter.com/kerryjaneellis1" target='_blank'><img className='me-3' src={Twitter} alt="Twitter" style={{ width: 24 }} /></a>
                    <a className='black-clr text-decoration-none' href="https://www.instagram.com/kerryellis79/" target='_blank'><img className='me-3' src={Instagram} alt="Instagram" style={{ width: 24 }} /></a>
                  </div>
                </div> */}
                {/* <p className='fs-4 mb-1 text-white'>2 Ebor St, London, E1 6AW</p> */}
                <p className="fs-4 mb-1 text-white">
                  For support with the website, please{" "}
                  <a href="mailto:hello@playfundwin.com" className="branding-2">
                    email us
                  </a>{" "}
                  here
                </p>
              </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-5 d-flex justify-content-end black-clr text-center">
              {/* <p className='mt-4 pt-3' style={{ fontSize: 13 }}>The Kerry Ellis Prize Draw is operated by <a className='text-decoration-none black-clr branding-1-hover' href="https://playfundwin.com/" target="_blank">Play Fund Win</a></p>
              <div className='d-flex' style={{ fontSize: 14 }}>
                <Link className='black-clr text-decoration-none pe-5 branding-1-hover font-face-sh-bold' to={'/terms-conditions'}>Terms & Conditions</Link>
                <Link className='black-clr text-decoration-none branding-1-hover font-face-sh-bold' to={'/privacy-policy'}>Privacy Policy</Link>
              </div> */}
              <div className="col-sm-12 col-md-12 col-lg-6">
                <div className="d-flex">
                  <img className="me-3" src={Visa} alt="Visa" style={{ width: 64 }} />
                  <img className="me-3" src={Mastercard} alt="Mastercard" style={{ width: 64 }} />{" "}
                  <span className="fs-5" style={{ color: "#eee", marginTop: 7, marginRight: 7 }}>
                    mastercard
                  </span>
                  <img className="me-3" src={Mastercard} alt="Mastercard" style={{ width: 64 }} />{" "}
                  <span className="fs-5" style={{ color: "#eee", marginTop: 7, marginRight: 7 }}>
                    maestro
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row py-4 copyright">
            <div className="col-md-6">
              <span className="fs-4 text-white" style={{ marginRight: 10 }}>
                Powered by
              </span>{" "}
              <a className="text-decoration-none black-clr branding-1-hover" href="https://playfundwin.com/" target="_blank">
                <img className="mx-auto" src={require("../images/pfw-white.png")} />
              </a>
            </div>
            <div className="col-md-6">
              <div className="d-flex justify-content-end" style={{ fontSize: 14 }}>
                <Link className="text-white text-decoration-none pe-5 fs-5 branding-1-hover font-face-sh-bold" to={"/terms-conditions"}>
                  Terms & Conditions
                </Link>
                <Link className="text-white text-decoration-none fs-5 branding-1-hover font-face-sh-bold" to={"/privacy-policy"}>
                  Privacy Policy
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
