import React, { Component } from "react";
import Slider from "react-slick";
import TrophyIcon from "../images/trophy-icon.svg";


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className="custom-arrow next-arrow"
      style={{ ...style, display: "block"}}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className="custom-arrow prev-arrow"
      style={{ ...style, display: "block"}}
      onClick={onClick}
    />
  );
}

export default function TestimonialSlider() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    // cssEase: "linear"
  };
  return (
    <div className="story-block-inner px-5 py-5 pt-0 shadow-lg rounded-3" style={{backgroundColor : '#645788'}}>
      <Slider {...settings}>
          <div className="story-block w-75" style={{margin : '0 auto'}}>
            <div className="row">
              <div className="col-md-12 text-center">
             <div className="testimonial-slider w-50 mb-5 bg-branding-cup p-2" style={{margin : '0 auto',borderBottomLeftRadius : 20,borderBottomRightRadius : 20}}>
             <h4 className="m-0">Note from our CEO</h4>
             </div>
                  <img className='w-25 rounded-3 mb-3' src={require('../images/ceo.png')} style={{margin : '0 auto'}} alt="jen"/>
             
              <div className="story-block-testimonial">
                  
                  <p className="text-white fst-italic" style={{fontSize: 24}}>It was brilliant to enjoy Note from our CEO with so many friends from across the industry, especially after not being able to hold theparty during the pandemic. It’s a critical time for Kerry Ellis and the people we serve. The money raised by Note from our CEO will help us to keep our work going, supporting colleagues across adland with their mental and financial health</p>
              </div>
              <div className="story-block-stats text-white">
                  <p className="m-0 position-relative"><strong style={{fontSize: 24}}>Sue Todd, CEO, Kerry Ellis</strong></p>
              </div>
              </div>
            </div>
          </div>
          <div className="story-block w-75" style={{margin : '0 auto'}}>
            <div className="row">
              <div className="col-md-12 text-center">
             <div className="testimonial-slider w-50 mb-5 bg-branding-cup p-2" style={{margin : '0 auto',borderBottomLeftRadius : 20,borderBottomRightRadius : 20}}>
             <h4 className="m-0">Note from our CEO</h4>
             </div>
                  <img className='w-25 rounded-3 mb-3' src={require('../images/ceo.png')} style={{margin : '0 auto'}} alt="jen"/>
             
              <div className="story-block-testimonial">
                  
                  <p className="text-white fst-italic" style={{fontSize: 24}}>It was brilliant to enjoy Note from our CEO with so many friends from across the industry, especially after not being able to hold theparty during the pandemic. It’s a critical time for Kerry Ellis and the people we serve. The money raised by Note from our CEO will help us to keep our work going, supporting colleagues across adland with their mental and financial health</p>
              </div>
              <div className="story-block-stats text-white">
                  <p className="m-0 position-relative"><strong style={{fontSize: 24}}>Sue Todd, CEO, Kerry Ellis</strong></p>
              </div>
              </div>
            </div>
          </div>

          <div className="story-block w-75" style={{margin : '0 auto'}}>
            <div className="row">
              <div className="col-md-12 text-center">
             <div className="testimonial-slider w-50 mb-5 bg-branding-cup p-2" style={{margin : '0 auto',borderBottomLeftRadius : 20,borderBottomRightRadius : 20}}>
             <h4 className="m-0 position-relative">Note from our CEO</h4>
             </div>
                  <img className='w-25 rounded-3 mb-3' src={require('../images/ceo.png')} style={{margin : '0 auto'}} alt="jen"/>
             
              <div className="story-block-testimonial">
                  
                  <p className="text-white fst-italic" style={{fontSize: 24}}>It was brilliant to enjoy Note from our CEO with so many friends from across the industry, especially after not being able to hold theparty during the pandemic. It’s a critical time for Kerry Ellis and the people we serve. The money raised by Note from our CEO will help us to keep our work going, supporting colleagues across adland with their mental and financial health</p>
              </div>
              <div className="story-block-stats text-white">
                  <p className="m-0 position-relative"><strong style={{fontSize: 24}}>Sue Todd, CEO, Kerry Ellis</strong></p>
              </div>
              </div>
            </div>
          </div>

      </Slider>
    </div>
  );
}
